import { useEffect, useState, useRef } from "react";
import { Box, Tooltip, IconButton, Pagination, Skeleton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../services/auth";
import { NavLink } from "react-router-dom";
import deleteIcon from "../../assets/images/icons/deleteIcon.svg";
import editIcon from "../../assets/images/icons/edit.svg";
import menuIcon from "../../assets/images/icons/menu.svg";
import feedback from "../../assets/images/icons/feedback.svg";
import {
  getTreeHistory,
  setIsDeleteTreeconfirmModalOpen,
  setIsGenerateTreeDialogOpen,
} from "../../reducers/treeMapSlice";
import treeLogo from "../../assets/images/icons/treeIcon.svg";
import { getTree } from "../../reducers/treeMapSlice";
import "./TreeHistory.css";
import { IsMobile } from "../../utils/deviceType";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function TreeHistory({ setSidebarOpened }) {
  const dispatch = useDispatch();
  const { user, loading } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const treeMapHistory = useSelector((state) => state.treeMap.history);
  const selectedTreeId = useSelector((state) => state.treeMap.selectedTreeId);
  const isTreeHistoryLoading = useSelector(
    (state) => state.treeMap.isTreeHistoryLoading
  );
  const totalPages = useSelector((state) => state.treeMap.totalPages);
  const isMobile = IsMobile();
  const [open, setOpen] = useState();
  const dropdownRef = useRef(null);
  const analytics = getAnalytics();

  useEffect(() => {
    if (!loading) {
      dispatch(getTreeHistory(currentPage));
    }
  }, [currentPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      !selectedTreeId &&
      !isTreeHistoryLoading &&
      treeMapHistory?.data &&
      Object.keys(treeMapHistory.data).length > 0
    ) {
      selectedTree(Object.keys(treeMapHistory.data)[0]);
    }
  }, [selectedTreeId, isTreeHistoryLoading]);

  const selectedTree = (key) => {
    isMobile && setSidebarOpened(false);
    if (!loading) {
      logEvent(analytics, `Tree Clicked From Tree History!`, {
        user: user.uid,
      });
      dispatch(getTree(key));
    }
  };

  const toggleOpenConfirmationModal = (treeID, treeName) => {
    dispatch(
      setIsDeleteTreeconfirmModalOpen({
        type: "tree",
        id: treeID,
        name: treeName,
      })
    );
  };

  const toggleOpenUpdateModal = (treeID) => {
    dispatch(
      setIsGenerateTreeDialogOpen({
        id: treeID,
      })
    );
  };

  return (
    <Box className="tree-sidebar">
      <Box className="title" sx={{ paddingLeft: isMobile ? "6px" : "12px" }}>
        {isMobile && (
          <IconButton onClick={() => setSidebarOpened(false)}>
            <ArrowBackIosIcon className="back-icon" fontSize="small" />
          </IconButton>
        )}
        <Box>Tree History</Box>
      </Box>
      <Box
        className="tree-sidebar-inner"
        sx={{
          paddingLeft: isMobile ? "16px" : "12px",
          height: `calc(100% - ${totalPages > 1 ? "160px" : "120px"})`,
        }}
      >
        {isTreeHistoryLoading ? (
          Array(4)
            .fill()
            .map((_, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "#f3f3f3",
                  borderRadius: "5px",
                  alignItems: "center",
                  height: "82px",
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Skeleton
                  variant="text"
                  width="90%"
                  height="20px"
                  sx={{ backgroundColor: "#e7e7e7", mt: "10px" }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width="90%"
                  height="30px"
                  sx={{ backgroundColor: "#e7e7e7", mt: 1 }}
                  animation="wave"
                />
              </div>
            ))
        ) : treeMapHistory &&
          treeMapHistory.data &&
          Object.keys(treeMapHistory.data).length > 0 ? (
          Object.keys(treeMapHistory.data).map((key, index) => (
            <Box
              key={key}
              onClick={() => selectedTree(key)}
              className={
                selectedTreeId === key || (selectedTreeId === "" && index === 0)
                  ? "tree-card selected-card"
                  : "tree-card"
              }
            >
              <img
                src={treeLogo}
                alt="Logo"
                width="19.2"
                height="19.2"
                style={{
                  cursor: "pointer",
                }}
              />
              <Box className="card-detail">
                <Tooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  title={treeMapHistory.data[key].title}
                  arrow
                >
                  <Box className="tree-header">
                    {treeMapHistory.data[key].title}
                  </Box>
                </Tooltip>
                <Tooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  title={treeMapHistory.data[key].context}
                  arrow
                >
                  <Box className="tree-description">
                    {treeMapHistory.data[key].context}
                  </Box>
                </Tooltip>
              </Box>
              <Box className="optionsContainer">
                <Box
                  className="menuIcon"
                  sx={{
                    visibility: (isMobile || open === key) && "visible",
                    backgroundColor:
                      !isMobile && open === key && "rgb(250, 250, 250)",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpen(key);
                  }}
                >
                  <img
                    src={menuIcon}
                    alt="Menu"
                    width="16"
                    height="16"
                    style={{
                      padding: 0,
                    }}
                  />
                </Box>
                <Box
                  className="dropdown-content"
                  sx={{ display: open === key ? "block" : "none" }}
                  ref={dropdownRef}
                >
                  <Box
                    className="dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOpenUpdateModal(key);
                    }}
                  >
                    <img
                      src={editIcon}
                      alt="Edit"
                      width="16"
                      height="16"
                      style={{
                        padding: 0,
                        marginRight: 5,
                      }}
                    />
                    Edit
                  </Box>
                  <Box
                    className="dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOpenConfirmationModal(
                        key,
                        treeMapHistory.data[key].title
                      );
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="Delete"
                      width="16"
                      height="16"
                      style={{
                        padding: 0,
                        marginRight: 5,
                      }}
                    />
                    Delete
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box className="sub-title"> No Results </Box>
        )}
      </Box>
      {totalPages > 1 && (
        <Pagination
          style={{ alignSelf: "center", marginTop: 15 }}
          size="small"
          shape="rounded"
          count={totalPages}
          color="primary"
          onChange={(e, val) => setCurrentPage(val)}
        />
      )}
      <NavLink
        to="https://antethink.nolt.io/newest"
        className="feedback"
        target="_blank"
        style={{
          textDecoration: "none",
          display: "flex",
          color: "#585858",
          backgroundColor: "#EEEEEE",
          cursor: "pointer",
          padding: "6px 12px",
          fontSize: "14px",
          borderRadius: "8px",
          margin: "auto",
          alignSelf: "center",
        }}
      >
        <img
          src={feedback}
          alt="Feedback"
          width="16"
          height="16"
          style={{
            padding: 0,
            marginRight: "7px",
            cursor: "pointer",
          }}
        />
        Feedback
      </NavLink>
    </Box>
  );
}
