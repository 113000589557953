import React from "react";
import googleIcon from "../../assets/images/icons/googleIcon.svg";
import { Button } from "@mui/material";
const GoogleSignInButton = ({handleSignInWithGoogle}) => {

  return (
    <Button
      onClick={handleSignInWithGoogle}
      variant="contained"
      type="submit"
      sx={{
        height: 45,
        width: "320px",
        maxWidth: "80vw",
        marginTop: "16px",
        fontSize: "16px",
        borderRadius: "6px",
        textTransform: "none",
        fontFamily: "sans-serif",
        backgroundColor: "white",
        boxShadow: "none",
        color: "#2d333a",
        border: "1px solid rgba(0, 0, 0, 0.23) !important",
        "&:hover": {
          backgroundColor: "white",
          boxShadow: "inset 0 0 0 150px rgba( 0,0,0 , 0.1 )",
        },
      }}
    >
      <img
        src={googleIcon}
        alt="Google Icon"
        style={{ height: "80%", marginRight: 8 }}
      />
      Continue with Google
    </Button>
  );
};

export default GoogleSignInButton;
