import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../services/auth";
import {
  Button,
  Menu,
  MenuItem,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";
import "./Header.css";
import {
  notify,
  setIsGenerateTreeDialogOpen,
  setUserDataLoading,
  treeHistoryTitleUpdate,
} from "../../reducers/treeMapSlice";
import { gotoCustomerPortal, signOut } from "../../services/api-service";
import { ReactComponent as DownloadTree } from "../../assets/images/icons/downloadTree.svg";
import { toPng, toSvg, toJpeg } from "html-to-image";
import Logo from "../../assets/images/logo.svg";
import { jsPDF } from "jspdf";
import { IsMobile } from "../../utils/deviceType";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useNavigate } from "react-router";

export default function Header() {
  const { user, loading } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTreeId = useSelector((state) => state.treeMap.selectedTreeId);
  const treeMap = useSelector((state) => state.treeMap);
  const [titleDisabled, setTitleDisabled] = useState(true);
  const [title, setTitle] = useState(null);
  const [treeLoaded, setTreeLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openDownload = Boolean(downloadAnchorEl);
  const isMobile = IsMobile();
  const analytics = getAnalytics();

  useEffect(() => {
    if (
      selectedTreeId &&
      treeMap?.history?.data &&
      treeMap.history.data[selectedTreeId]
    ) {
      setTitle(treeMap.history.data[selectedTreeId].title);
    } else if (selectedTreeId === "" && title !== null) {
      setTitle(null);
    }
  }, [treeMap, selectedTreeId]);

  const handleTitleFocusChange = () => {
    setTitleDisabled(true);
    (async () => {
      if (!loading) {
        if (user) {
          if (title && title !== "") {
            if (title === treeMap.history.data[selectedTreeId].title) {
              setTitleDisabled(true);
              return;
            }
            user.getIdToken().then((userIdToken) => {
              dispatch(
                treeHistoryTitleUpdate(userIdToken, selectedTreeId, title)
              );
            });
          } else {
            setTitleDisabled(false);
          }
        }
      }
    })();
  };

  const onAccountClick = async () => {
    dispatch(setUserDataLoading(true));
    const userIdToken = await user.getIdToken();
    const response = await gotoCustomerPortal({
      userIdToken,
    });
    if (response?.error) {
      notify(response.error.code, "error");
    } else {
      if (response?.customerPortalUrl) {
        window.location.href = response?.customerPortalUrl;
      }
    }
    setTimeout(() => {
      dispatch(setUserDataLoading(false));
    }, 1000);
  };

  const toggleDialog = () => {
    logEvent(analytics, `Tree Generation Dialog Opened!`, {
      user: user.uid,
    });
    dispatch(setIsGenerateTreeDialogOpen());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const HeaderMenuIcon = ({ label, onClick, ...props }) => (
    <MenuItem sx={{ fontSize: "14px" }} onClick={onClick} {...props}>
      {label}
    </MenuItem>
  );

  const formatToMethodMap = {
    svg: toSvg,
    png: toPng,
    jpg: toJpeg,
  };

  const downloadTree = async (format) => {
    logEvent(analytics, `Tree Download Clicked!`, {
      user: user.uid,
    });

    setDownloadAnchorEl(null);
    const clonedTree = document.getElementById("currentTree").cloneNode(true);
    const tempDiv = document.createElement("div");
    tempDiv.style.cssText = "position: relative; background: white";
    const newImg = document.createElement("img");
    newImg.style.cssText =
      "position: absolute; top: 20px; left: 20px; width: 25%";
    tempDiv.appendChild(clonedTree);

    const imgBlob = await fetch(Logo).then((response) => response.blob());
    const imgBlobUrl = URL.createObjectURL(imgBlob);
    newImg.src = imgBlobUrl;

    tempDiv.appendChild(newImg);
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      tempDiv.appendChild(newImg);
      document.body.appendChild(tempDiv);
      if (format === "pdf") {
        const pdf = new jsPDF("p", "mm", "a4");
        await toJpeg(tempDiv);
        const imgData = await toJpeg(tempDiv);
        const desiredHeightInMM = pdf.internal.pageSize.height;
        const aspectRatio = tempDiv.clientWidth / tempDiv.clientHeight;
        const desiredWidthInMM = desiredHeightInMM * aspectRatio;
        const xPos = (pdf.internal.pageSize.width - desiredWidthInMM) / 2;
        const yPos = 0;

        pdf.addImage(
          imgData,
          "JPEG",
          xPos,
          yPos,
          desiredWidthInMM,
          desiredHeightInMM
        );
        pdf.save("exported-tree.pdf");
        return;
      }
      await formatToMethodMap[format](tempDiv);
      const dataUrl = await formatToMethodMap[format](tempDiv);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `exported-tree.${format}`;
      link.click();
    } catch (error) {
      console.error(`Error exporting ${format}:`, error);
    } finally {
      URL.revokeObjectURL(imgBlobUrl);
      document.body.removeChild(tempDiv);
    }
  };

  useEffect(() => {
    if (treeLoaded) {
      setTreeLoaded(
        treeMap.data && !loading && !treeMap.loading && !treeMap.isTreeLoading
      );
    } else {
      const timeoutId = setTimeout(() => {
        setTreeLoaded(
          treeMap.data && !loading && !treeMap.loading && !treeMap.isTreeLoading
        );
      }, 4000);
      return () => clearTimeout(timeoutId);
    }
  }, [treeMap.data, loading, treeMap.loading, treeMap.isTreeLoading]);

  return (
    <div className="header-container">
      <div>
        <Button
          className="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={
            <div className="username">
              {user?.email ? user.email.charAt(0) : null}
            </div>
          }
        ></Button>
        <Menu
          className="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={0}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: "100px",
              maxWidth: "100px",
              borderRadius: "3px",
              margin: "2px 0px 2px",
              border: "1px solid #dde0e4",
              boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
              WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <HeaderMenuIcon
            label="Privacy"
            onClick={() =>
              window.open("https://www.antethink.com/privacy-policy", "_blank")
            }
          />
          <HeaderMenuIcon label="Account" onClick={() => onAccountClick()} />
          <HeaderMenuIcon
            label="Sign-out"
            onClick={() => (signOut(), navigate("/signin"))}
          />
        </Menu>
      </div>
      {/* Load title of the loaded d3 chart */}
      <Box style={{ display: "flex", alignItems: "center" }}>
        {!titleDisabled ? (
          <TextField
            disabled={titleDisabled}
            inputProps={{ style: { fontFamily: "ClashDisplay-regular" } }}
            className="tree-title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => handleTitleFocusChange()}
            autoFocus={true}
          />
        ) : (
          <Tooltip title="Edit Title" arrow>
            <Box
              className="tree-title"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              onClick={() => setTitleDisabled(false)}
            >
              {title}
            </Box>
          </Tooltip>
        )}
        <div>
          <Tooltip title="Download Tree" arrow>
            <IconButton
              className="downloadTree"
              onClick={(event) => setDownloadAnchorEl(event.currentTarget)}
              sx={{
                padding: "0px",
                minWidth: "5px",
                margin: "0px 8px",
                visibility: treeLoaded ? "visible" : "hidden",
                opacity: treeLoaded ? 1 : 0,
                backgroundColor: "transparent",
              }}
            >
              <DownloadTree />
            </IconButton>
          </Tooltip>
          <Menu
            className="user-menu"
            anchorEl={downloadAnchorEl}
            open={openDownload}
            onClose={() => setDownloadAnchorEl(null)}
            elevation={0}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              sx: {
                minWidth: "100px",
                maxWidth: "100px",
                borderRadius: "3px",
                margin: "2px 0px 2px",
                border: "1px solid #dde0e4",
                boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
                WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <HeaderMenuIcon
              label="Save as SVG"
              onClick={() => downloadTree("svg")}
              style={{ fontSize: "12px" }}
            />
            <HeaderMenuIcon
              label="Save as PNG"
              onClick={() => downloadTree("png")}
              style={{ fontSize: "12px" }}
            />
            <HeaderMenuIcon
              label="Save as JPG"
              onClick={() => downloadTree("jpg")}
              style={{ fontSize: "12px" }}
            />
            <HeaderMenuIcon
              label="Save as PDF"
              onClick={() => downloadTree("pdf")}
              style={{ fontSize: "12px" }}
            />
          </Menu>
        </div>
      </Box>
      <Box className="add-tree" onClick={toggleDialog}>
        <AddCircleIcon color="primary"></AddCircleIcon>
        {!isMobile && <Box className="new-title">New Tree</Box>}
      </Box>
    </div>
  );
}
