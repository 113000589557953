import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddchartIcon from "@mui/icons-material/Addchart";
import { setIsGenerateTreeDialogOpen } from "../../reducers/treeMapSlice";
import DecisionTreeVisualization from "../DecisionTreeVisualization/DecisionTreeVisualization";
import Loader from "../Loader/Loader";

export default function Layout() {
  const treeMap = useSelector((state) => state.treeMap);
  const treeLoading = useSelector((state) => state.treeMap.isTreeLoading);
  const loading = useSelector((state) => state.treeMap.loading);
  const [showTree, setShowTree] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowTree(true);
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Box style={{ height: "100%", position: "relative" }}>
      {!showTree || treeLoading || loading ? (
        <Loader
          treeGeneratingLoader
          type={treeLoading ? "treeCreating" : "treeLoading"}
        />
      ) : treeMap.data ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <DecisionTreeVisualization
            treeMap={treeMap}
            treeLoading={treeLoading}
          />
        </Box>
      ) : (
        <Box className="create-chart-button-container">
          <Tooltip title="Generate new Tree">
            <IconButton
              onClick={() => dispatch(setIsGenerateTreeDialogOpen())}
              color="primary"
            >
              <AddchartIcon
                className="add-chart-icon"
                fontSize="large"
                color="primary"
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
