import axios from "axios";
import { CLOUD_FUNCTIONS_ORIGIN } from "../functions-origin";
// import Cookies from "js-cookie";
import store from "../reducers/store";
import { reset } from "../reducers/treeMapSlice";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  getAuth,
  signOut as firebaseSignOut,
} from "firebase/auth";

const apiUrl = `${CLOUD_FUNCTIONS_ORIGIN}/api`;
let generatePlanCancelAPI = null;
// const userAuthToken = Cookies.get("jwt")

const commonHeaders = (token) => ({ Authorization: `Bearer ${token}`, "Content-Type": "application/json", });

const handleError = (error) => {
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    signOut()
  }
}

const getAccessToken = async () => {
  try {
    const user = getAuth().currentUser;
    const accessToken = await user.getIdToken();
    return accessToken;
  } catch (error) {
    console.error("Error fetching access token:", error);
  }
}

export const signIn = async ({ email, password }) => {
  return signInWithEmailAndPassword(getAuth(), email, password);
}

export const signUp = async ({ email, password }) => {
  return createUserWithEmailAndPassword(getAuth(), email, password);
}

export const signOut = async () => {
  store.dispatch(reset());
  // Cookies.remove("jwt")
  await firebaseSignOut(getAuth());
}

export const sendPasswordResetLink = async ({ email }) => {
  const actionCodeSettings = {
    url: process.env.NODE_ENV === "development"
      ? "http://localhost:3000/signin"
      : "https://app.antethink.com/signin",
    handleCodeInApp: false,
  };
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(getAuth(), email, actionCodeSettings)
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const getUserData = async (userIdToken) => {
  try {
    const res = await axios.get(`${apiUrl}/users`, {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const createTree = async ({ event, context, title, requestType }) => {
  try {
    const token = await getAccessToken();
    const res = await axios.put(`${apiUrl}/tree`, { event, context, title, requestType }, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const editTree = async ({ key, title, event, context, requestType }) => {
  try {
    const token = await getAccessToken();
    const res = await axios.put(
      `${apiUrl}/edit-tree/${key}`, { title, event, context, requestType }, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const getTree = async ({ key }) => {
  try {
    const token = await getAccessToken();
    const res = await axios.get(`${apiUrl}/tree/${key}`, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const getTreeHistory = async ({ currentPage }) => {
  try {
    const token = await getAccessToken();
    const res = await axios.get(`${apiUrl}/tree-history/10/${currentPage}`, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const removeTreeHistoryRecord = async ({ deleteTreeId }) => {
  try {
    const token = await getAccessToken();
    const res = await axios.delete(`${apiUrl}/tree/${deleteTreeId}`, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const isUserExistWithEmail = async ({ email }) => {
  try {
    const res = await axios.get(`${apiUrl}/check-user-exist/${email}`)
    return res?.data;
  } catch (error) {
    console.log(error);
  }
}

export const udpateTreeHistoryTitle = async ({ treeHistoryId, title }) => {
  try {
    const token = await getAccessToken();
    const res = await axios.post(`${apiUrl}/tree-history-title/${treeHistoryId}`, { title }, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const generatePlan = async ({ key, context, nodeData }) => {
  try {
    const token = await getAccessToken();
    if (generatePlanCancelAPI) {
      generatePlanCancelAPI.cancel();
    }
    // save the new request for cancellation
    generatePlanCancelAPI = axios.CancelToken.source();
    const res = await axios.put(`${apiUrl}/generate-plan`, { key, context, nodeData }, {
      headers: commonHeaders(token),
      cancelToken: generatePlanCancelAPI.token
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const removePlanRecord = async ({ selectedTreeId, nodeData }) => {
  try {
    const token = await getAccessToken();
    const res = await axios.delete(`${apiUrl}/plan-remove/${selectedTreeId}/${nodeData}`, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const gotoCustomerPortal = async () => {
  try {
    const token = await getAccessToken();
    const res = await axios.post(`${apiUrl}/customer-portal`, {}, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const getActionPlanNodes = async ({ selectedTreeId }) => {
  try {
    const token = await getAccessToken();
    const res = await axios.get(`${apiUrl}/get-planned-nodes/${selectedTreeId}`, {
      headers: commonHeaders(token),
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}
