import React from "react";
import { setIsGenerateTreeDialogOpen } from "../../reducers/treeMapSlice";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joyride from "react-joyride";
import { IsMobile } from "../../utils/deviceType";

export default function OnBoardingFlow({ setSidebarOpened }) {
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [onboardingIndex, setOnboardingIndex] = useState(0);
  const dispatch = useDispatch();
  const isMobile = IsMobile();
  const treesHistory = useSelector((state) => state.treeMap.history);
  const userData = useSelector((state) => state.treeMap.userData);

  const Step = (title, description, target, placement, extraProps) => ({
    content: (
      <div style={{ marginTop: "-12px" }}>
        <div
          style={{
            fontFamily: "ClashDisplay-medium",
            fontSize: "20px",
            marginBottom: "20px",
          }}
        >
          {title}
        </div>
        {description}
      </div>
    ),
    placement: placement,
    target: target,
    ...extraProps,
  });

  const onBoardingSteps = [
    Step(
      "Welcome to AnteThink!",
      "Let's start with a quick product tour and we will have you up and running in no time!",
      ".header",
      "center",
      {
        locale: {
          next: "Start",
        },
      }
    ),
    Step(
      //   "Generate Tree Link",
      "",
      "Begin your journey with AnteThink by generating your decision tree and start visualizing the potential paths of your decision or event.",
      ".add-tree"
    ),
    Step(
      "Choose your analysis mode",
      <>
        <ul style={{ textAlign: "start" }}>
          <li style={{ marginBottom: "10px" }}>
            <span style={{ fontFamily: "ClashDisplay-Medium" }}>
              Optionality Mode
            </span>{" "}
            helps compare different choices.
          </li>
          <li style={{ marginBottom: "10px" }}>
            <span style={{ fontFamily: "ClashDisplay-Medium" }}>
              Second Order Mode
            </span>{" "}
            reveals the indirect effects of your decisions.
          </li>
          <li>
            <span style={{ fontFamily: "ClashDisplay-Medium" }}>
              Inversion Mode
            </span>{" "}
            focuses on avoiding negative outcomes.
          </li>
        </ul>
      </>,
      ".treeTypesContainer",
      "left"
    ),
    Step(
      //   "Enter Action or Event",
      "",
      "Define your decision or event. Try to describe it in a short, clear sentence to the AI.",
      ".actionNameDescription",
      "left"
    ),
    Step(
      //   "Enter Context",
      "",
      "Provide additional details about your situation. The more context you give, the better AnteThink can tailor its analysis to your specific circumstances.",
      ".actionNameContext",
      "left",
      {
        locale: {
          next: "Finish",
        },
      }
    ),
    Step(
      "Your generated trees will be here",
      "Need to tweak your analysis? Easily adjust your context or delete and start fresh. This lets you fine-tune your decision tree for better, more relevant outcomes.",
      ".tree-sidebar-inner",
      isMobile ? "center" : "left"
    ),
    Step(
      "Create an action plan",
      "Craft a tailored action plan to seize opportunities or mitigate risks. This tool helps you strategize effectively, turning insights into concrete steps for your specific outcome or event.",
      ".childlessNode"
    ),
    Step(
      "",
      "Click to download a copy of your decision tree. Keep a handy reference of your pathways and insights for future use.",
      ".downloadTree"
    ),
    Step(
      "",
      "Manage your account settings, view your subscription details, and update payment information here. Keep your account up to date to continue enjoying uninterrupted service.",
      ".basic-button",
      "bottom",
      {
        locale: {
          last: isMobile && "Finish",
        },
      }
    ),
    Step(
      "",
      "Your input is valuable. Share your thoughts and suggestions with the AnteThink team.",
      ".feedback",
      "top",
      {
        locale: {
          last: "Finish",
        },
      }
    ),
  ];

  useEffect(() => {
    if (treesHistory) {
      if (!treesHistory?.data) {
        setRunOnboarding(true);
      } else if (
        treesHistory?.data &&
        Object.keys(treesHistory?.data).length === 1 &&
        !userData?.createdTree
      ) {
        isMobile && setSidebarOpened(true);
        setTimeout(() => {
          setRunOnboarding(true);
          setOnboardingIndex(5);
        }, 100);
      }
    }
  }, [treesHistory]);

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;
    const toggleGenerateTreeDialog = () => {
      dispatch(setIsGenerateTreeDialogOpen());
      index === 4
        ? setRunOnboarding(false)
        : setTimeout(() => {
            setOnboardingIndex(onboardingIndex + (action === "next" ? 1 : -1));
          }, 100);
    };
    if (type === "step:after") {
      if (action === "next") {
        isMobile && index === 5 && setSidebarOpened(false);
        index === 1 || index === 4
          ? toggleGenerateTreeDialog()
          : setOnboardingIndex(onboardingIndex + 1);
      } else if (action === "prev") {
        index === 2
          ? toggleGenerateTreeDialog()
          : setOnboardingIndex(onboardingIndex - 1);
      }
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={runOnboarding}
      disableScrolling
      showSkipButton
      steps={isMobile ? [...onBoardingSteps.slice(0, -1)] : onBoardingSteps}
      stepIndex={onboardingIndex}
      styles={{
        buttonNext: {
          backgroundColor: "#1D77B7",
          color: "#fff",
          fontSize: "14px",
          fontFamily: "sans-serif",
          outline: "none",
        },
        buttonSkip: {
          color: "#888",
          fontSize: "13px",
          fontFamily: "sans-serif",
        },
        buttonBack: {
          display: onboardingIndex === 5 && "none",
          color: "#1D77B7",
          fontSize: "13px",
          fontFamily: "sans-serif",
        },
        overlay: {
          cursor: "default",
        },
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
}
