import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { useAuth } from "../services/auth";
import { gotoCustomerPortal, getUserData } from "../services/api-service";
import { notify } from "../reducers/treeMapSlice";
import { ReactComponent as BrandLogo } from "../assets/images/logo.svg";

const Subscribe = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({ email: "", id: "" });
  const [userLoading, setUserLoading] = useState(false);

  const GetUserData = async () => {
    if (user) {
      setUserLoading(true);
      await user.getIdToken().then(async (token) =>
        getUserData(token)
          .then(async (res) => {
            if (res?.isSubscriptionActive) {
              const response = await gotoCustomerPortal();
              if (response?.error) {
                notify(response.error.code, "error");
              } else {
                if (response?.customerPortalUrl) {
                  window.location.href = response?.customerPortalUrl;
                  if (window.location.href == response?.customerPortalUrl) {
                    setUserLoading(false);
                  }
                }
              }
            } else {
              setCustomer({ email: user.email, id: user.uid });
              setTimeout(() => {
                setUserLoading(false);
              }, 1500);
            }
          })
          .catch(() => notify("An error occured!", "error"))
      );
    } else navigate("/signin");
  };

  useEffect(() => {
    if (!loading && !userLoading) {
      GetUserData();
    }
  }, [user, loading]);

  return (
    <Box>
      {userLoading || !user ? (
        <Loader />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", mt: "5vh" }}>
          <BrandLogo
            onClick={() => navigate("/signin")}
            style={{ alignSelf: "center", cursor: "pointer" }}
          />
          <Box sx={{ mt: "50px" }}>
            <stripe-pricing-table
              class="stripe-pricing-table"
              pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
              publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
              client-reference-id={customer.id}
              customer-email={customer.email}
            ></stripe-pricing-table>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Subscribe;
