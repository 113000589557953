import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import isEmail from "is-email";
import { ReactComponent as BrandLogo } from "../assets/images/logo.svg";
import { notify } from "../reducers/treeMapSlice";
import {
  isUserExistWithEmail,
  sendPasswordResetLink,
} from "../services/api-service";
import { IsMobile } from "../utils/deviceType";

const ForgotPassword = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const [errorMessage, setErrorMessage] = useState(undefined);
  const isMobile = IsMobile();

  const onSubmit = (data) => {
    const { email } = data;
    if (email) {
      isUserExistWithEmail({ email }).then((res) => {
        if (res.exists) {
          sendPasswordResetLink({ email }).then((res) => {
            if (res) {
              notify("Password reset link sent successfully.", "success");
              setErrorMessage(undefined);
              reset({ email: "" });
            } else {
              setErrorMessage("Unable to send password reset link.");
            }
          });
        } else {
          setErrorMessage(
            "User with given email is not registered with AnteThink"
          );
        }
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: isMobile ? "8%" : "4rem",
      }}
    >
      <BrandLogo />
      <Typography
        sx={{
          color: "#2d333a",
          margin: `${isMobile ? "20%" : "120px"}  0px 24px`,
          fontFamily: "ClashDisplay-Medium",
          fontSize: 30,
        }}
      >
        Reset your passowrd
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "320px",
          maxWidth: "80vw",
        }}
      >
        <Controller
          control={control}
          name="email"
          rules={{
            required: "Enter an email",
            validate: {
              validateEmail: (email) => isEmail(email) || "Enter a valid email",
            },
          }}
          render={({ field }) => (
            <TextField
              sx={{
                "&:hover fieldset": {
                  border: "1px solid rgba(0, 0, 0, 0.23) !important",
                  borderLeft: "3px solid #1D77B7 !important",
                },
                "& .Mui-focused fieldset": {
                  border: "1px solid #1D77B7 !important",
                },
                "& input": {
                  height: "1.5em",
                  padding: "14px",
                },
              }}
              label="Email Address"
              {...field}
              helperText={errors.email?.message}
              error={Boolean(errors.email)}
              type="email"
            />
          )}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "26px",
            mb: 0,
          }}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{
              height: 45,
              borderRadius: "6px",
              fontSize: "16px",
              fontFamily: "sans-serif",
              textTransform: "none",
              width: "100%",
              backgroundColor: "#1D77B7",
              boxShadow: "none",
              "&:hover": {
                background: "#0f639e",
                boxShadow: "none",
              },
            }}
          >
            Send Reset Link
          </Button>
        </Box>
        <Typography
          sx={{
            alignSelf: "center",
            fontSize: "14px",
            marginTop: "14px",
            color: "#2d333a",
          }}
        >
          Back to{" "}
          <NavLink
            to="/signin"
            style={{
              textDecoration: "none",
              color: "#1D77B7",
              cursor: "pointer",
            }}
          >
            Log in
          </NavLink>
        </Typography>
        <Box
          sx={{
            marginTop: 2,
            textAlign: "center",
            color: "var(--text-primary)",
          }}
        >
          <Typography
            sx={{
              visibility: errorMessage ? "visible" : "hidden",
            }}
            color="error"
          >
            {errorMessage}
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default ForgotPassword;
