import "./App.css";
import { useAuth } from "./services/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";
import Dashboard from "./pages/Dashboard";
import Loader from "./components/Loader/Loader";
import ErrorBoundary from "./ErrorBoundary";
import { getUserData } from "./services/api-service";
import { setUserData } from "./reducers/treeMapSlice";
// import Cookies from "js-cookie";

function App() {
  const { user, loading } = useAuth();
  const dispatch = useDispatch();
  // const userAuthToken = Cookies.get("jwt")
  const navigate = useNavigate();
  const [dataState, setDataState] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const userData = useSelector((state) => state.treeMap.userData);

  const GetUserData = async () => {
    if (user) {
      setDataLoading(true);
      await user.getIdToken().then(async (token) => await getUserData(token).then((res) => {
        if (res?.isSubscriptionActive) {
          dispatch(setUserData(res));
        } else navigate("/subscribe");
        setDataLoading(false);
      }).catch(() => setDataState("error")))
    }
    else navigate("/signin");
  }

  useEffect(() => {
    if (!loading && !dataLoading) {
      GetUserData()
    }
  }, [user, loading]);

  const child =
    (loading || !userData) ? <Loader /> : (
      dataState === "error" ? (
        <Typography>An error occured.</Typography>
      ) : <Dashboard />
    )

  return (
    <ErrorBoundary>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {child}
      </div>
    </ErrorBoundary>
  );
}

export default App;
