import { Box, Button, Card, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContentCopyIcon from "../../assets/images/icons/copy.svg";
import deleteIcon from "../../assets/images/icons/deleteIcon.svg";
import {
  setVisibleRightPanel,
  setIsDeleteTreeconfirmModalOpen,
  setSelectedTreeNode,
  notify,
} from "../../reducers/treeMapSlice";
import Loader from "../Loader/Loader";
import Sparkles from "../../assets/images/icons/sparkles.svg";
import "./Plans.css";
import { IsMobile } from "../../utils/deviceType";

export default function Plans({ setSidebarOpened }) {
  const dispatch = useDispatch();
  const isMobile = IsMobile();
  const planData = useSelector((state) => state.treeMap.planData);
  const plansLoading = useSelector((state) => state.treeMap.plansLoading);
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );
  const selectedTreePlannedNodeNames = useSelector(
    (state) => state.treeMap.selectedTreePlannedNodeNames
  );

  const displayData = (data, colonIndex) => {
    let boldText = "",
      normalText = "";
    boldText = data.substring(0, colonIndex);
    normalText = data.substring(colonIndex + 1);
    return { boldText, normalText };
  };

  const copyFunction = () => {
    navigator.clipboard.writeText(
      document.getElementsByClassName("card")[0].innerText,
    );
    notify("Plan data copied to clipboard", "success");
  };

  const toggleOpenConfirmationModal = () => {
    dispatch(setIsDeleteTreeconfirmModalOpen({ type: "plan" }));
  };

  return (
    <Box className="plans-panel">
      <Box className="plans-header">
        <IconButton
          onClick={() => (
            dispatch(setSelectedTreeNode(null)),
            isMobile && setSidebarOpened(false),
            setTimeout(
              () => {
                dispatch(setVisibleRightPanel("TreeHistory"));
              },
              isMobile ? 500 : 0
            )
          )}
          color="primary"
        >
          <ArrowBackIosIcon className="back-icon" fontSize="small" />
        </IconButton>
        <Box>{isMobile ? "Plan" : "Tree History"}</Box>
      </Box>
      <Box className="plan-title-box">
        <img
          src={Sparkles}
          alt="Sparkles"
          width={20}
          height={20}
          style={{ marginTop: "5px" }}
        />
        {selectedTreeNode ? (
          <Box className="plan-title">{selectedTreeNode?.data.name}</Box>
        ) : null}
      </Box>
      {plansLoading ? (
        <Loader
          planGeneratingLoader
          alreadyExistsPlan={selectedTreePlannedNodeNames?.includes(
            selectedTreeNode?.data.name
          )}
        />
      ) : planData && Object.keys(planData).length > 0 ? (
        <Box>
          {Object.keys(planData).map((key, index) => (
            <Box key={index}>
              <Card className="card">
                <Box className="plan-section">
                  {planData[key].data &&
                    Object.keys(planData[key].data).map((event, values) => (
                      <Box key={values}>
                        <Box className="plan-key">{event}:</Box>
                        {event &&
                          planData[key].data[event]?.length > 0 &&
                          planData[key].data[event].map((data, index) => {
                            const colonIndex = data?.indexOf(":");
                            const startsWithString = /^[a-zA-Z]/.test(data);
                            if (colonIndex !== -1) {
                              const result = displayData(data, colonIndex);
                              return (
                                <Box key={index} className="plan-key-data">
                                  {startsWithString && (
                                    <span>
                                      <b>{index + 1}. </b>
                                    </span>
                                  )}
                                  <span
                                    style={{
                                      fontFamily: "ClashDisplay-Medium",
                                    }}
                                  >
                                    {result.boldText}
                                  </span>
                                  :{result.normalText}
                                </Box>
                              );
                            } else {
                              return (
                                <Box key={index} className="plan-key-data">
                                  {startsWithString && (
                                    <span>
                                      <b>{index + 1}. </b>
                                    </span>
                                  )}
                                  <span>{data}</span>
                                </Box>
                              );
                            }
                          })}
                      </Box>
                    ))}
                </Box>
              </Card>
              <Button
                onClick={copyFunction}
                variant="outline"
                startIcon={<img alt="CopyIcon" src={ContentCopyIcon} />}
                className="copy-delete-button"
              >
                Copy
              </Button>
              <Button
                variant="outline"
                startIcon={<img alt="CopyIcon" src={deleteIcon} />}
                className="copy-delete-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleOpenConfirmationModal();
                }}
              >
                Delete
              </Button>{" "}
            </Box>
          ))}
        </Box>
      ) : (
        <Box> No Plans generated </Box>
      )}
    </Box>
  );
}
