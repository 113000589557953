import { createSlice } from "@reduxjs/toolkit";
import * as apiService from "../services/api-service";
import { toast } from "react-toastify";

const initialState = {
  data: null,
  history: null,
  planData: null,
  isGenerateTreeDialogOpen: false,
  isDeleteTreeconfirmModalOpen: false,
  userDataLoading: false,
  userData: null,
  selectedTreeId: "",
  selectedDeleteTreeId: "",
  selectedDeleteTreeName: "",
  selectedUpdateTreeId: "",
  loading: false,
  plansLoading: false,
  isTreeHistoryLoading: false,
  isTreeLoading: false,
  visibleRightPanel: "TreeHistory",
  totalPages: 1,
  selectedTreeNode: null,
  deleteConfirmationModalType: "",
  selectedTreePlannedNodeNames: [],
};

const isMobile = window.innerWidth < window.innerHeight

// Slice
const treeMapSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setHistoryLoading: (state, action) => {
      state.isTreeHistoryLoading = action.payload;
    },
    setPlanData: (state, action) => {
      state.planData = action.payload;
    },
    setIsGenerateTreeDialogOpen: (state, action) => {
      state.isGenerateTreeDialogOpen = !state.isGenerateTreeDialogOpen;
      state.selectedUpdateTreeId = action.payload?.id;
    },
    setIsDeleteTreeconfirmModalOpen: (state, action) => {
      state.isDeleteTreeconfirmModalOpen = !state.isDeleteTreeconfirmModalOpen;
      state.deleteConfirmationModalType = action.payload?.type;
      state.selectedDeleteTreeId = action.payload?.id;
      state.selectedDeleteTreeName = action.payload?.name;
    },
    setSelectedTreeId: (state, action) => {
      state.selectedTreeId = action.payload;
    },
    setVisibleRightPanel: (state, action) => {
      state.visibleRightPanel = action.payload;
    },
    setPlanLoading: (state, action) => {
      state.plansLoading = action.payload;
    },
    setUserDataLoading: (state, action) => {
      state.userDataLoading = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSelectedTreePlannedNodeNames: (state, action) => {
      state.selectedTreePlannedNodeNames = action.payload;
    },
    setSelectedTreeNode: (state, action) => {
      state.selectedTreeNode = action.payload;
    },
    setTreeLoading: (state, action) => {
      state.isTreeLoading = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    }
  },
});

// Actions
export const {
  reset,
  setData,
  setHistory,
  setIsGenerateTreeDialogOpen,
  setIsDeleteTreeconfirmModalOpen,
  setSelectedTreeId,
  setTreeLoading,
  setHistoryLoading,
  setTreeHistoryLoading,
  setVisibleRightPanel,
  setPlanLoading,
  setPlanData,
  setUserDataLoading,
  setUserData,
  setSelectedTreePlannedNodeNames,
  setSelectedTreeNode,
  setLoading,
  setTotalPages,
} = treeMapSlice.actions;
export default treeMapSlice.reducer;

export const createTree = (title, event, context, requestType, userIdToken) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setTreeLoading(true));
    apiService.createTree({
      userIdToken,
      event,
      context,
      title,
      requestType,
    })
      .then((response) => {
        if (response?.message) {
          notify(response.message.code, response.message.type);
        }
        if (response?.error) {
          notify(response.error.code, "error");
          // reject();
        } else {
          let historyData = getState().treeMap.history;
          const newRecordKey = Object.keys(response.treeRecord)[0];
          historyData = {
            ...historyData,
            data: {
              [newRecordKey]: Object.values(response.treeRecord)[0],
              ...historyData.data,
            },
          };
          dispatch(setHistory(historyData));
          dispatch(setSelectedTreeId(Object.keys(response.treeRecord)[0]));
          dispatch(setData(response.data));
          dispatch(setVisibleRightPanel("TreeHistory"));
          resolve();
        }
      })
      .catch((error) => {
        dispatch(setData(null));
        if (error?.response?.data?.code === 'ECONNRESET') {
          notify("Network error: Please check your internet connection.", "error");
        } else {
          console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setData(getState().treeMap.data));
        dispatch(setTreeLoading(false));
      });
  });
};

export const editTree = (title, event, context, requestType, key, userIdToken) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setSelectedTreeId(key));
    dispatch(setTreeLoading(true));
    apiService.editTree({
      userIdToken,
      key,
      title,
      event,
      context,
      requestType,
    })
      .then((response) => {
        if (response?.message) {
          notify(response.message.code, response.message.type);
        }
        if (response?.error) {
          notify(response.error.code, "error");
          // reject();
        } else {
          let historyData = getState().treeMap.history;
          const newRecordKey = Object.keys(response.treeRecord)[0];
          let newData = { ...historyData.data };
          newData[newRecordKey] = {
            ...newData[newRecordKey],
            context: response.treeRecord[newRecordKey].context,
            eventName: response.treeRecord[newRecordKey].eventName,
            title: response.treeRecord[newRecordKey].title,
            requestType: response.treeRecord[newRecordKey].requestType
          };
          // newData[newRecordKey] = { ...newData[newRecordKey], eventName: response.treeRecord[newRecordKey].eventName };
          historyData = { ...historyData, data: newData };
          historyData = {
            ...historyData,
            data: {
              [newRecordKey]: Object.values(response.treeRecord)[0],
              ...historyData.data,
            },
          };
          dispatch(setHistory(historyData));
          dispatch(setSelectedTreeId(newRecordKey));
          dispatch(setData(response.data));
          dispatch(setVisibleRightPanel("TreeHistory"));
          resolve();
        }
      })
      .catch((error) => {
        dispatch(setData(null));
        if (error?.response?.data?.code === 'ECONNRESET') {
          notify("Network error: Please check your internet connection.", "error");
        } else {
          console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setData(getState().treeMap.data));
        dispatch(setTreeLoading(false));
      });
  });
};

export const getTree = (key) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const historyData = getState().treeMap.history.data;
    if (historyData[key] && getState().treeMap.selectedTreeId !== key) {
      dispatch(setLoading(true));
      dispatch(setSelectedTreeId(key));
      apiService.getTree({
        key,
        event: historyData[key].eventName,
        context: historyData[key].context,
        title: historyData[key].title,
      })
        .then((response) => {
          if (response?.message) {
            // notify(response.message.code, response.message.type);
            dispatch(setData(null));
          } else if (response?.error) {
            notify(response.error.code, "error");
            dispatch(setData(null));
            // reject();
          } else {
            dispatch(getSelectedTreeActionPlanNodeNames(key));
            dispatch(setData(response));
          }
          resolve();
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setData(null));
          dispatch(setLoading(false));
          // console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        })
    }
  });
};

const getSelectedTreeActionPlanNodeNames = (selectedTreeId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiService.getActionPlanNodes({ selectedTreeId })
      .then((data) => {
        if (data.data) {
          dispatch(setSelectedTreePlannedNodeNames(data.data));
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      })
  })
};

export const getTreeHistory = (currentPage) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setHistoryLoading(true));
    apiService.getTreeHistory({
      currentPage
    })
      .then((response) => {
        if (response?.message) {
          // notify(response.message.code, response.message.type);
          dispatch(setHistory({ data: {} }));
        }
        if (response?.error) {
          notify(response.error.code, "error");
          dispatch(setHistory({ data: {} }));
        } else {
          dispatch(setTotalPages(response.totalPages))
          dispatch(setHistory(response));
          dispatch(setHistoryLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
};

export const treeHistoryRemove =
  (userIdToken) => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      let deleteTreeId = state.treeMap.selectedDeleteTreeId;
      apiService.removeTreeHistoryRecord({ deleteTreeId })
        .then((response) => {
          if (response?.message) {
            notify(response.message.code, response.message.type);
          }
          if (response?.error) {
            notify(response.error.code, "error");
          } else {
            let updatedHistory = {
              data: {},
            };
            Object.keys(state.treeMap.history.data).forEach((key) => {
              if (key !== deleteTreeId) {
                updatedHistory["data"][key] = state.treeMap.history.data[key];
              }
            });
            if (Object.keys(updatedHistory.data).length <= 0) {
              dispatch(setData(null));
            }
            dispatch(setHistory(updatedHistory));
            dispatch(
              setIsDeleteTreeconfirmModalOpen({ type: "tree", id: "", name: "" })
            );
            dispatch(setSelectedTreeId(""));
          }
          resolve();
        })
        .catch((error) => {
          notify(error.message, "error");
        })
    })
  };

export const treeHistoryTitleUpdate =
  (userIdToken, treeHistoryId, title) => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      apiService
        .udpateTreeHistoryTitle({
          userIdToken,
          treeHistoryId,
          title,
        })
        .then((response) => {
          if (response?.message) {
            notify(response.message.code, response.message.type);
          }
          if (response?.error) {
            notify(response.error.code, "error");
          } else {
            let updatedHistory = {
              data: {},
            };
            Object.keys(state.treeMap.history.data).forEach((key) => {
              updatedHistory["data"][key] = state.treeMap.history.data[key];
              if (key === treeHistoryId) {
                updatedHistory["data"][key] = {
                  ...updatedHistory["data"][key],
                  title,
                };
              }
            });
            dispatch(setHistory(updatedHistory));
          }
          resolve();
        })
        .catch((error) => {
          notify(error.message, "error");
        });
    });
  };

export const generatePlan = (nodeData) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setSelectedTreeNode(nodeData));
    dispatch(setVisibleRightPanel("Plans"));
    dispatch(setPlanLoading(true));

    const historyData = getState().treeMap.history.data;
    const selectedTreeId = getState().treeMap.selectedTreeId;

    if (historyData[selectedTreeId]) {
      const responsePromise = apiService.generatePlan({
        key: selectedTreeId,
        context: historyData[selectedTreeId].context,
        nodeData: nodeData.data.name,
      });
      responsePromise
        .then((response) => {
          if (response?.data?.message) {
            notify(response.data.message.code, response.data.message.type);
          }
          if (response?.data?.error) {
            notify(response.data.error.code, "error");
            dispatch(setPlanData(null));
            // reject(null);
          } else if (response?.status === 200) {
            dispatch(getSelectedTreeActionPlanNodeNames(selectedTreeId))
              .then(() => {
                dispatch(setPlanData(response.data[selectedTreeId]));
                dispatch(setPlanLoading(false));
                resolve(true);
              })
              .catch((error) => {
                reject(error);
              });
          }
        })
        .catch((error) => {
          dispatch(setPlanData(null));
          if (error?.response?.data?.code === "ECONNRESET") {
            notify(
              "Unable to process your request. Please check your internet connection or try again later.",
              "error"
            );
          } else {
            notify(error.message, "error");
            console.log("ERROR MESSAGE", error);
          }
          dispatch(setPlanLoading(false));
          // reject();
        });
    }
  });
};

export const removePlan = (userIdToken, selectedTreeId, nodeData) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    apiService.removePlanRecord({
      selectedTreeId,
      nodeData,
    }).then((response) => {
      if (response?.message) {
        notify(response.message.code, response.message.type);
      }
      if (response?.error) {
        notify(response.error.code, "error");
      } else {
        let updatedPlan = { data: {} };
        Object.keys(state.treeMap.planData).reduce((item, key) => {
          if (key !== nodeData) {
            updatedPlan["data"][key] = state.treeMap.planData[key];
          }
          resolve(item);
        }, updatedPlan);
        if (Object.keys(updatedPlan.data).length <= 0) {
          dispatch(setPlanData(null));
        } else {
          dispatch(setPlanData(updatedPlan));
        }
        dispatch(
          setSelectedTreePlannedNodeNames(
            state.treeMap.selectedTreePlannedNodeNames.filter(
              (elm) => elm !== nodeData
            )
          )
        );
        dispatch(setIsDeleteTreeconfirmModalOpen({ type: "plan" }));
        document.getElementById(`spanNum${state.treeMap.selectedTreeNode.id}`).remove();
        dispatch(setSelectedTreeNode(null));
        dispatch(setVisibleRightPanel("TreeHistory"));
      }
      resolve();
    })
      .catch((error) => {
        notify(error.message, "error");
      })
  })
};

export const notify = (title, type) => {
  let color = "#3a9c3b";
  if (type === "error") {
    color = "#d73f35";
  } else if (type === "info") {
    color = "#3498DB"
  }
  return toast(title, {
    position: "top-right",
    autoClose: type === "success" ? 1000 : 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    type,
    style: {
      background: color,
      fontFamily: "ClashDisplay-Medium",
      width: isMobile && "80vw",
      margin: isMobile ? "4vw" : 0,
      borderRadius: "4px",
    },
    theme: "colored",
  });
};
